<template>
  <div class="commonProblemReply">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="问题标题:"
        :rules="[
          { required: true, message: '请输入问题标题', trigger: 'blur' },
        ]"
        prop="feedbackTitle"
      >
        <v-input
          disabled
          placeholder="请输入问题标题"
          v-model="form.feedbackTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="问题类型:"
        :rules="[
          { required: true, message: '请选择问题类型', trigger: 'blur' },
        ]"
        prop="feedbackType"
      >
        <v-select
          disabled
          :options="problemTypesOps"
          v-model="form.feedbackType"
        />
      </el-form-item>
      <el-form-item
        label="问题内容:"
        :rules="[
          { required: false, message: '请输入问题内容', trigger: 'blur' },
        ]"
        prop="feedbackContent"
      >
        <div id="editor">
          <v-ueditor
            isDisable
            uniqueId="feedbackContent"
            :maxLength="20000"
            v-model="form.feedbackContent"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="平台显示:"
        :rules="[
          { required: true, message: '请选择是否平台显示', trigger: 'blur' },
        ]"
        prop="isPlatformShow"
      >
        <v-select :options="isPlatformShowOps" v-model="form.isPlatformShow" />
      </el-form-item>
      <el-form-item
        label="是否置顶:"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isRecommend"
      >
        <v-select :options="isRecommendOps" v-model="form.isRecommend" />
      </el-form-item>
      <el-form-item
        label="回复内容:"
        :rules="[
          { required: true, message: '请输入回复内容', trigger: 'blur' },
        ]"
        prop="optContent"
      >
        <div>
          <v-ueditor
            uniqueId="optContent"
            :maxLength="20000"
            v-model="form.optContent"
          />
        </div>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { editFeedbackInformation } from "./api";
import {
  problemTypesOps,
  problemTypesMap,
  isPlatformShowOps,
  isRecommendOps,
} from "./map";
export default {
  name: "commonProblemReply",
  data() {
    return {
      // 问题类型
      problemTypesOps: problemTypesOps(),
      // 是否平台显示
      isPlatformShowOps: isPlatformShowOps(),
      // 是否置顶
      isRecommendOps: isRecommendOps(),
      submitConfig: {
        submitUrl: editFeedbackInformation,
      },
      form: {
        id: "",
        feedbackTitle: "",
        feedbackType: "",
        feedbackContent: "",
        isPlatformShow: "",
        isRecommend: "",
        optContent: "",
      },
    };
  },
  mounted() {
    const {
      id,
      feedbackTitle,
      feedbackType,
      feedbackContent,
      isPlatformShow,
      isRecommend,
      optContent,
    } = this.$route.params;
    this.form.id = id;
    this.form.feedbackTitle = feedbackTitle;
    this.form.feedbackType = feedbackType;
    this.form.feedbackContent = feedbackContent;
    this.form.isPlatformShow = isPlatformShow;
    this.form.isRecommend = isRecommend;
    this.form.optContent = optContent;

    this.$setBreadList("回复");
  },

  methods: {
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.commonProblemReply {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 190px !important;
  }
  /deep/ textarea {
    height: 150px;
  }
  .info {
    margin-left: 10px;
  }
  .extraData {
    width: 600px;
    height: 500px;
    #jsoneditor {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style scoped>
@import "~jsoneditor/dist/jsoneditor.min.css";
.IotAgentServiceConfig-wrapper {
  background-color: white;
  overflow: hidden;
}

.search-wrapper {
  margin: 20px;
  display: flex;
}

.pull-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

#jsoneditor {
  margin: 20px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
}

#jsoneditor >>> .jsoneditor-menu {
  background-color: #409eff;
  border-bottom: 1px solid #409eff;
}

#jsoneditor >>> .jsoneditor.jsoneditor-mode-text {
  border: thin solid #409eff;
}

#jsoneditor >>> .jsoneditor-poweredBy {
  display: none;
}
</style>
