import { mapHelper } from "@/utils/common";
// 回复状态
const optStatus = [
  {
    value: undefined,
    label: "全部",
  },
  {
    value: 0,
    label: "待处理",
  },
  {
    value: 1,
    label: "已处理",
  },

];
const { map: optStatusMap, setOps: optStatusOps } =
  mapHelper.setMap(optStatus);
// 问题类型
const problemTypes = [
  {
    value: undefined,
    label: "全部",
  },
  {
    value: 1,
    label: "平台问题",
  },
  {
    value: 2,
    label: "接口问题",
  },
  {
    value: 3,
    label: "意见建议",
  },

];
const { map: problemTypesMap, setOps: problemTypesOps } =
  mapHelper.setMap(problemTypes);
// 是否平台显示
const isPlatformShow = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },

];
const { map: isPlatformShowMap, setOps: isPlatformShowOps } =
  mapHelper.setMap(isPlatformShow);
// 是否推荐
const isRecommend = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },

];
const { map: isRecommendMap, setOps: isRecommendOps } =
  mapHelper.setMap(isRecommend);

export {
  optStatusMap,
  optStatusOps,
  problemTypesMap,
  problemTypesOps,
  isPlatformShowMap,
  isRecommendMap,
  isPlatformShowOps,
  isRecommendOps
};
