//反馈信息列表
const getFeedbackInformationList = `/gateway/hc-portal/feedback/manage/pageList`;
//反馈信息-处理反馈信息
const editFeedbackInformation = `/gateway//hc-portal/feedback/manage/opt`;
//反馈信息 - 处理显示状态
const updateFeedbackInformation = `/gateway/hc-portal/feedback/manage/changeStatus`;
export {
  getFeedbackInformationList,
  editFeedbackInformation,
  updateFeedbackInformation
};
